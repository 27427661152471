import React, { useState, useEffect } from "react";
import { useUserInfoViewData } from "../../context/UserInfoContext";
import { UserInfo } from "./UserInfoView";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import { TextField, Box, Alert } from "@mui/material";
import { Button } from "react-bootstrap";

const UserProfile = () => {
  const { user, setUser } = useUserInfoViewData();
  const { token } = useToken();
  const [loading, setLoading] = useState(false);
  const [searchId, setSearchId] = useState("");
  const [error, setError] = useState<string | null>(null);

  async function fetchUserById(userId: string) {
    setLoading(true);
    setError(null);
    const url = new URL(`${Base_Url}/users/user`);
    url.searchParams.append("userId", userId);

    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) throw new Error("User not found");
      const data = await response.json();
      if (data) {
        setUser(data);
        window.history.pushState({}, '', `?id=${userId}`);
      }
    } catch (error) {
      console.error(error);
      setError("User not found");
      setUser(null);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchId.trim()) {
      fetchUserById(searchId.trim());
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get("id");
    
    if (userId && (!user?.id || userId !== user.id)) {
      fetchUserById(userId);
      setSearchId(userId);
    }

    const handleUrlChange = () => {
      const newParams = new URLSearchParams(window.location.search);
      const newUserId = newParams.get("id");
      if (newUserId && (!user?.id || newUserId !== user.id)) {
        fetchUserById(newUserId);
        setSearchId(newUserId);
      }
    };

    window.addEventListener('popstate', handleUrlChange);
    return () => window.removeEventListener('popstate', handleUrlChange);
  }, [user]);

  return (
    <div>
      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 2, display: 'flex', gap: 1 }}>
        <TextField
          size="small"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
          placeholder="Enter User ID"
          error={!!error}
        />
        <Button 
          variant="primary" 
          type="submit"
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Search'}
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {user != null ? (
        <UserInfo user={user} />
      ) : (
        !error && <div>No user selected</div>
      )}
    </div>
  );
};

export default UserProfile;
