import React, {
  useState,
  useMemo,
  MouseEventHandler,
  MouseEvent,
  useEffect,
} from "react";
import { capitalizeWord } from "../../utils/stringUtil";
import "./UserInfo.scss";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import { Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import { UserActivesInfo } from "./UserActivesInfo"
import UserImagesView from './UserImagesView'
import { Box } from "@mui/material";
import UserActionsView from './UserActionsView'

export const UserInfo = (props: { user: any }) => {
  let user = props.user;
  const { token, setToken } = useToken();
  const [matches, setMatches] = useState(null);

  async function getMatchData() {
    const userId = user.id;
    if (!userId) {
      return;
    }
    const url = new URL(`${Base_Url}/matches/messages`);
    url.searchParams.append("userId", userId);
    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };
    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        if (data) {
          setMatches(data.matches);
        } else {
          throw data.errorMessage;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getMatchData();
  }, [user]);

  const UserInfoView = () => {
    return (
      <div
        className="UserInfoBasicsWrapper"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxHeight: "40vh", 
          overflow: "scroll",
        }}
      >
        {/* Full width h2 on its own row */}
        <div style={{ width: "100%", marginBottom: "20px" }}>
          <h2>
            User Profile:{" "}
            {capitalizeWord(user.firstName) +
              " " +
              capitalizeWord(user.lastName)}
          </h2>
        </div>

        {/* Three columns in a row, each taking up 1/3 of the width */}
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: "1", paddingRight: "10px" }}>
            {user.createdAt && (
              <div>
                <b>Created At:</b> {new Date(user.createdAt).toLocaleString()}
              </div>
            )}
            {user.updatedAt && (
              <div>
                <b>Updated At:</b> {new Date(user.updatedAt).toLocaleString()}
              </div>
            )}
            <div>
              <b>Banned:</b> {user.banned ? "TRUE" : "FALSE"}
            </div>
            <div>
              <b>ID:</b> {user._id}
            </div>
            <div>
              <b>User ID:</b> {user.id}
            </div>
            <div>
              <b>Email:</b> {user.email}
            </div>
            <div>
              <b>First Name:</b> {user.firstName}
            </div>
            <div>
              <b>Last Name:</b> {user.lastName}
            </div>
            <div>
              <b>Date of Birth:</b> {user.dob}
            </div>
            <div>
              <b>Gender:</b> {user.gender}
            </div>
            <div>
              <b>Phone Number:</b> {user.phoneNumber}
            </div>
            <div>
              <b>Education:</b> {user.profileData.education}
            </div>
            <div>
              <b>Occupation:</b> {user.profileData.occupation}
            </div>
            <div>
              <b>Onboarded:</b> {user.onboarded ? "Yes" : "No"}
            </div>
            <div>
              <b>Area Code:</b> {user.areaCode}
            </div>
            <div>
              <b>Target Genders:</b> {user.targetGenders.join(", ")}
            </div>
          </div>

          {/* Second Column */}
          <div style={{ flex: "1", paddingRight: "10px" }}>
            <h3>Prompts</h3>
            {Object.keys(user.profileData?.prompts).length > 0 &&
              Object.entries(user.profileData.prompts).map(
                ([key, prompt]: any) => (
                  <div key={prompt._id}>
                    <div>{prompt.promptValue}</div>
                  </div>
                )
              )}
          </div>

          {/* Third Column */}
          <div style={{ flex: "1" }}>
          <UserActivesInfo activeVenues={user.active_venues} activeEvents={user.active_events}/>
          </div>
        </div>
      </div>
    );
  };

  const UserMatchMessagesView = ({ matches }: any) => {
    if (matches == null) {
      return <div>No matches</div>;
    }

    return (
      <div>
        <h2> Matches </h2>

        {matches.map((match: any) => (
          <div key={match.matchId} className="matchWrapper">
            <div>matchId: {match.matchId}</div>
            <div>
              matchedWith:
              {capitalizeWord(match.matchedWith?.firstName) +
                " " +
                capitalizeWord(match.matchedWith?.lastName)}
            </div>

            <div className="messagesWrapper" style={{ paddingBottom: "30px" }}>
              <div style={{ fontWeight: 600 }}>Messages:</div>
              {match.messages.map((message: any) => {
                if (message.sentBy == user.id) {
                  return (
                    <div key={message.id}>
                      {" "}
                      {user.firstName}: {message.messageText}
                    </div>
                  );
                } else {
                  return (
                    <div key={message.id}>
                      {" "}
                      {match.matchedWith.firstName}: {message.messageText}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const UserLikes = ({ likes, dislikes }: any) => {
    return (
      <div>
        <h3>Like</h3>
        <div>
          {user.likes.map((like: any, index: any) => (
            <div key={index}>
              <b>User:</b> {like}
            </div>
          ))}
        </div>
        <h3>Dislike</h3>
        <div>
          {user.dislikes.map((dislike: any, index: any) => (
            <div key={index}>
              <b>User:</b> {dislike}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const BanUser = () => {
    const [reason, setReason] = useState("");

    async function unbanUser() {
      const userId = user.id;
      if (!userId) {
        return;
      }
      const url = new URL(`${Base_Url}/ban/user`);
      url.searchParams.append("userId", userId);
      const options = {
        method: "DELETE",
        headers: new Headers({
          Authorization: token,
          "Content-Type": "application/json",
        }),
      };
      return fetch(url, options)
        .then((response) => {
          if (response.ok) {
            console.log(`Unbanned user: ${user.id}`);
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          console.log(data);
          if (data) {
            user = { ...user, banned: false };
          } else {
            throw data.errorMessage;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    async function banUser() {
      const userId = user.id;
      if (!userId) {
        return;
      }
      const url = new URL(`${Base_Url}/ban/user`);
      const bodyData = {
        userId: userId,
        reason: reason,
      };
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: token,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(bodyData),
      };
      return fetch(url, options)
        .then((response) => {
          if (response.ok) {
            console.log(`Banned user: ${user.id}`);
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          console.log(data);
          user = { ...user, banned: true };
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (user.banned == true) {
      return (
        <Box sx={{ mb: 2 }}>
          <Button variant="primary" onClick={unbanUser}>
            Un-ban User
          </Button>
        </Box>
      );
    }

    return (
      <Box sx={{ mb: 2, display: 'flex', gap: 1 }}>
        <TextField
          size="small"
          id="outlined-controlled"
          label="Ban Reason"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setReason(event.target.value);
          }}
        />
        <Button variant="primary" onClick={banUser}>
          Ban User
        </Button>
      </Box>
    );
  };

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div className="UserInfoWrapper" onClick={stopPropagation}>
      <UserActionsView user={user} />

      <UserInfoView />

      <UserImagesView imageUrls={user.imageUrls} />

      <UserLikes likes={user.likes} dislikes={user.dislikes}></UserLikes>

      <UserMatchMessagesView matches={matches}></UserMatchMessagesView>
    </div>
  );
};
