import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import { KeyboardArrowRight, KeyboardArrowDown, Close } from "@mui/icons-material";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";

interface ReferralsGridProps {
  referrals: any[];
}

const ReferralsGridView = ({ referrals }: ReferralsGridProps) => {
  const [openRows, setOpenRows] = useState<{ [key: string]: boolean }>({});
  const { token } = useToken();

  const handleRowClick = (userId: string) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [userId]: !prevOpenRows[userId],
    }));
  };

  const handleDeleteReferral = async (referralId: string) => {
    const url = `${Base_Url}/referrals/referral?id=${referralId}`;
    const options = {
      method: "DELETE",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) throw new Error("Failed to delete referral");
      // Optionally, update the state to remove the deleted referral from the UI
      console.log(`Referral with ID ${referralId} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting referral:", error);
    }
  };

  const confirmAndDeleteReferral = (referralId: string, phoneNumber: string) => {
    const confirmed = window.confirm(`Are you sure you want to delete the referral for ${phoneNumber}?`);
    if (confirmed) {
      handleDeleteReferral(referralId);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="referrals table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>UserId</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Num Sent</TableCell>
            <TableCell>Num Verified</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {referrals.map((referral) => (
            <React.Fragment key={referral.userId}>
              <TableRow>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleRowClick(referral.userId)}
                  >
                    {openRows[referral.userId] ? (
                      <KeyboardArrowDown />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell>{referral.userId}</TableCell>
                <TableCell>{referral.name}</TableCell>
                <TableCell>{referral.numSent}</TableCell>
                <TableCell>{referral.numVerified}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={openRows[referral.userId]} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                      <Table size="small" aria-label="referrals">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell>Referred User ID</TableCell>
                            <TableCell>Referred User Name</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Updated At</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {referral.referrals.map((ref: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell>
                                <IconButton
                                  aria-label="delete referral"
                                  size="small"
                                  onClick={() => confirmAndDeleteReferral(ref.id, ref.referredUserPhoneNumber)}
                                >
                                  <Close />
                                </IconButton>
                              </TableCell>
                              <TableCell>{ref.referredUserId || "N/A"}</TableCell>
                              <TableCell>{ref.referredUserName || "N/A"}</TableCell>
                              <TableCell>{ref.referredUserPhoneNumber}</TableCell>
                              <TableCell>{ref.status}</TableCell>
                              <TableCell>{new Date(ref.createdAt).toLocaleString()}</TableCell>
                              <TableCell>{new Date(ref.updatedAt).toLocaleString()}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReferralsGridView;
