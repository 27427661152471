import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import './Login.scss'
import { Base_Url } from '../../constants/constants';

interface LoginCredentials {
    email: string;
    password: string;
}

const login = async (credentials: LoginCredentials) => {
    return fetch(`${Base_Url}/auth/login`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then((response) => {
        if (response.ok) {
            return response.json();
        }
        throw response;
    }).then(data => {
        console.log(data)
        if (data.success && data?.token) {
            return data.token
        }
        throw data.errorMessage;
    }).catch(error => {
        console.error(error);
    });
}

function Login({ setToken }: any) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const token = await login({
            email, 
            password,
        });
        if (token) {
            setToken(token);
        }
    }

    const validateForm = () => {
        return email && password;
    }

    return (
        <div className='portal-login-wrapper'>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="email">
                    <Form.Label className='label'>Email</Form.Label>
                    <Form.Control
                        autoFocus
                        type="name"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                <Button size="lg" type="submit" disabled={!validateForm()}>
                    Login
                </Button>
            </Form>
        </div>
    )
}

export default Login;