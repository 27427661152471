import React from "react";
import { Button } from "react-bootstrap";
import useToken from "../Login/useToken";
import { Base_Url } from "../../constants/constants";

interface RemoveUserLikesProps {
  userId: string;
  onLikesRemoved?: () => void;
}

const RemoveUserLikes: React.FC<RemoveUserLikesProps> = ({ userId, onLikesRemoved }) => {
  const { token } = useToken();

  const handleRemoveLikes = async () => {
    if (!userId) {
      return;
    }
    const url = new URL(`${Base_Url}/users/user/likes`);
    url.searchParams.append("userId", userId);
    const options = {
      method: 'DELETE',
      headers: new Headers({
        'Authorization': token,
        'Content-Type': 'application/json'
      })
    };

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        console.log("Likes removed successfully.");
        if (onLikesRemoved) {
          onLikesRemoved();
        }
      } else {
        console.error("Failed to remove likes.");
      }
    } catch (error) {
      console.error("An error occurred while removing likes:", error);
    }
  };

  return (
    <Button variant="primary" onClick={handleRemoveLikes}>
      Remove Likes
    </Button>
  );
};

export default RemoveUserLikes;
