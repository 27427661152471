import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHead, TableSortLabel, Checkbox } from "@mui/material";

interface ReferralOfferingGridProps {
  offerings: any[];
  selected: any;
  setSelected: any;
}

export const ReferralOfferingGridView = (props: ReferralOfferingGridProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { selected, setSelected } = props;
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");

  const offerings = props.offerings || [];

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - offerings.length) : 0;

  const handleOfferingRequestSort = () => {
    const isAsc = orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    sortOfferings(isAsc ? "desc" : "asc");
  };

  const sortOfferings = (direction: "asc" | "desc") => {
    return offerings.sort((a, b) => {
      if (direction === "asc") return a.startDate - b.startDate;
      else return b.startDate - a.startDate;
    });
  };

  const sortedOfferings = React.useMemo(() => {
    return sortOfferings(orderDirection);
  }, [offerings, orderDirection]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = offerings.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, offeringItem: any) => {
    setSelected(offeringItem);
  };

  const isSelected = (offeringItem: any) => selected?.id === offeringItem.id;

  return (
    <div className="offeringsGridWrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500, tableLayout: 'fixed' }} aria-label="custom pagination table">
          <TableHead>
            <TableRow key="header">
              <TableCell padding="checkbox" sx={{ width: '5%' }}>
                <Checkbox
                  color="primary"
                  indeterminate={false}
                  checked={false}
                  inputProps={{
                    "aria-label": "select all offerings",
                  }}
                  disabled
                />
              </TableCell>
              <TableCell sx={{ width: '15%' }}>Type</TableCell>
              <TableCell sx={{ width: '15%' }}>Current</TableCell>
              <TableCell sx={{ width: '25%' }}>Dates</TableCell>
              <TableCell align="left" sx={{ width: '30%' }}>Title</TableCell>
              <TableCell align="left" sx={{ width: '30%' }}>Body Text</TableCell>
              <TableCell align="left" sx={{ width: '30%' }}>Text Message</TableCell>
              <TableCell align="left" sx={{ width: '30%' }}>More Info Links</TableCell>
              <TableCell align="left" sx={{ width: '30%' }}>Title Image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? offerings.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : offerings
            ).map((offeringItem: any, index: any) => {
              const isItemSelected = isSelected(offeringItem);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  key={offeringItem.id}
                  role="checkbox"
                  onClick={(event) => handleClick(event, offeringItem)}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox" sx={{ verticalAlign: 'top' }}>
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>{offeringItem.type}</TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    {offeringItem.isCurrent ? "Yes" : "No"}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    <div>Start: {offeringItem.startDate ? new Date(offeringItem.startDate).toLocaleDateString() : "N/A"}</div>
                    <div>End: {offeringItem.endDate ? new Date(offeringItem.endDate).toLocaleDateString() : "N/A"}</div>
                  </TableCell>
                  <TableCell align="left" sx={{ verticalAlign: 'top' }}>{offeringItem.titleStringBolded + " " + offeringItem.titleString}</TableCell>
                  <TableCell align="left" sx={{ verticalAlign: 'top' }}>{offeringItem.bodyText}</TableCell>
                  <TableCell align="left" sx={{ verticalAlign: 'top' }}>{offeringItem.textMessageString}</TableCell>
                  <TableCell align="left" sx={{ verticalAlign: 'top' }}>
                    {offeringItem.moreInfoLinks.map((link: any) => (
                      <div key={link._id}>
                        <a href={link.url} target="_blank" rel="noopener noreferrer">
                          {link.title}
                        </a>
                      </div>
                    ))}
                  </TableCell>
                  <TableCell align="left" sx={{ verticalAlign: 'top' }}>
                    {offeringItem.titleImageUrl ? (
                      <img src={offeringItem.titleImageUrl} alt="Title" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                    ) : (
                      "No Image"
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={7}
                count={offerings.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
