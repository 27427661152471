import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import ReferralsGridView from "./ReferralsGridView";

const ReferralsView = () => {
  const [referrals, setReferrals] = useState<any[]>([]);
  const { token } = useToken();

  async function getReferrals() {
    const url = new URL(`${Base_Url}/referrals`);
    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };
    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data) {
          setReferrals(
            Object.entries(data).map(([userId, details]) => {
              if (typeof details === 'object' && details !== null) {
                return { userId, ...details };
              } else {
                console.warn(`Details for userId ${userId} is not an object:`, details);
                return { userId }; // or handle it differently if needed
              }
            })
          );
        } else {
          throw data.errorMessage;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getReferrals();
  }, []);

  return (
    <div className="referralsGridWrapper">
      <div className="button">
        <Button variant="primary" onClick={getReferrals}>
          Refresh
        </Button>
      </div>
      <ReferralsGridView referrals={referrals} />
    </div>
  );
};

export default ReferralsView;
