import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";

const BanUserButton = ({ user }: any) => {
  const { token } = useToken();
  const [reason, setReason] = useState("");

  const unbanUser = async () => {
    const userId = user.id;
    if (!userId) {
      return;
    }
    const url = new URL(`${Base_Url}/ban/user`);
    url.searchParams.append("userId", userId);
    const options = {
      method: "DELETE",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        console.log(`Unbanned user: ${user.id}`);
        const data = await response.json();
        console.log(data);
        user = { ...user, banned: false };
      } else {
        throw response;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const banUser = async () => {
    const userId = user.id;
    if (!userId) {
      return;
    }
    const url = new URL(`${Base_Url}/ban/user`);
    const bodyData = {
      userId: userId,
      reason: reason,
    };
    const options = {
      method: "POST",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(bodyData),
    };
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        console.log(`Banned user: ${user.id}`);
        const data = await response.json();
        console.log(data);
        user = { ...user, banned: true };
      } else {
        throw response;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {user.banned ? (
        <Button variant="primary" onClick={unbanUser}>
          Un-ban User
        </Button>
      ) : (
        <>
          <TextField
            size="small"
            id="outlined-controlled"
            label="Ban Reason"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setReason(event.target.value);
            }}
          />
          <Button variant="primary" onClick={banUser}>
            Ban User
          </Button>
        </>
      )}
    </>
  );
};

export default BanUserButton;
