import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button, Form, Row, Col } from "react-bootstrap";
import { UserGrid } from "./UserGrid";
import { UsersDistributionDashboard } from "./UsersDistributionDashboard";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import "./UserManager.scss";

// Define an interface for the search configuration
interface SearchConfig {
  firstName: string;
  lastName: string;
  showOnboardedOnly: boolean;
  sortBy: string;
}

const filterOutEmptyProfiles = (users: any) => {
  if (users && users?.length > 0) {
    return users.filter(
      (user: any) => user.firstName != null && user.firstName !== ""
    );
  }
};

const UserManager = () => {
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState<readonly any[]>([]);
  const { token } = useToken();
  
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchConfig, setSearchConfig] = useState<SearchConfig>({ firstName: '', lastName: '', showOnboardedOnly: false, sortBy: 'updatedAt' });

  async function getUsers(page = 0, limit = 10, firstName = '', lastName = '', showOnboardedOnly = false, sortBy = 'updatedAt') {
    setLoading(true);
    const url = new URL(`${Base_Url}/users`);
    url.searchParams.append("page", (page + 1).toString());
    url.searchParams.append("limit", limit.toString());
    if (firstName) url.searchParams.append("firstName", firstName);
    if (lastName) url.searchParams.append("lastName", lastName);
    if (showOnboardedOnly) url.searchParams.append("showOnboardedOnly", "true");
    url.searchParams.append("sortBy", sortBy);

    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };
    
    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data) {
          setUsers(filterOutEmptyProfiles(data.users) || []);
          setTotalUsers(data.total);
        } else {
          throw new Error(data.errorMessage);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    getUsers(page, rowsPerPage, searchConfig.firstName, searchConfig.lastName, searchConfig.showOnboardedOnly, searchConfig.sortBy);
  }, [page, rowsPerPage, searchConfig.showOnboardedOnly, searchConfig.sortBy]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchConfig((prev) => ({ ...prev, [name]: value }));
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === 'true';
    setSearchConfig((prev) => ({ ...prev, showOnboardedOnly: value }));
  };

  const handleSearchSubmit = () => {
    setPage(0);
    getUsers(0, rowsPerPage, searchConfig.firstName, searchConfig.lastName, searchConfig.showOnboardedOnly, searchConfig.sortBy);
  };

  const handleClearSearch = () => {
    setSearchConfig({ firstName: '', lastName: '', showOnboardedOnly: false, sortBy: 'updatedAt' });
    setPage(0);
    getUsers(0, rowsPerPage);
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchConfig((prev) => ({ ...prev, sortBy: value }));
  };

  if (loading) {
    return <div>Loading....</div>;
  }

  // if (users?.length > 0) {
    return (
      <div className="restaurantsGridWrapper">
        <UsersDistributionDashboard />
        <Form className="my-3">
          <Row className="align-items-center">
            <Col xs="auto">
              <Form.Group>
                <Form.Label><strong>Onboarded</strong></Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="showOnboardedOnly"
                    value="true"
                    checked={searchConfig.showOnboardedOnly === true}
                    onChange={handleRadioChange}
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="showOnboardedOnly"
                    value="false"
                    checked={searchConfig.showOnboardedOnly === false}
                    onChange={handleRadioChange}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group>
                <Form.Label><strong>Sort</strong></Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    label="Updated"
                    name="sortBy"
                    value="updatedAt"
                    checked={searchConfig.sortBy === 'updatedAt'}
                    onChange={handleSortChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Created"
                    name="sortBy"
                    value="createdAt"
                    checked={searchConfig.sortBy === 'createdAt'}
                    onChange={handleSortChange}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group controlId="firstName">
                <Form.Control
                  type="text"
                  name="firstName"
                  value={searchConfig.firstName}
                  onChange={handleSearchChange}
                  placeholder="First Name"
                />
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group controlId="lastName">
                <Form.Control
                  type="text"
                  name="lastName"
                  value={searchConfig.lastName}
                  onChange={handleSearchChange}
                  placeholder="Last Name"
                />
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Button
                variant="primary"
                onClick={handleSearchSubmit}
                style={{ marginRight: '6px' }}
              >
                Search
              </Button>
              <Button
                variant="secondary"
                onClick={handleClearSearch}
              >
                Clear
              </Button>
            </Col>
          </Row>
        </Form>
        <UserGrid
          users={users}
          selected={selected}
          setSelected={setSelected}
          page={page}
          rowsPerPage={rowsPerPage}
          totalUsers={totalUsers}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
        <div className="button refresh-button">
          <Button variant="primary" onClick={() => getUsers(page, rowsPerPage, searchConfig.firstName, searchConfig.lastName, searchConfig.showOnboardedOnly)}>
            Refresh
          </Button>
        </div>
      </div>
    );
  // }

  // return (
  //   <div className="restaurantsGridWrapper">
  //     <div className="emptyPortalResturantsGrid">Empty</div>
  //   </div>
  // );
};

export default UserManager;