import React from "react";
import { Button } from "react-bootstrap";
import { Box } from "@mui/material";
import BanUserButton from './BanUserButton'; // Import the BanUser component
import RemoveUserDislikes from './RemoveUserDislikes';
import RemoveUserLikes from './RemoveUserLikes';

const UserActionsView = ({ user }: any) => {
  const removeLikes = () => {
    // Implement the logic to remove likes
    console.log("Removing likes...");
  };

  const removeDislikes = () => {
    console.log("Removed dislikes")
  };

  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <BanUserButton user={user}/>
      <RemoveUserLikes userId={user.id} onLikesRemoved={removeLikes} />
      <RemoveUserDislikes userId={user.id} onDislikesRemoved={removeDislikes} />
    </Box>
  );
};

export default UserActionsView;
