import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import { ReferralOfferingGridView } from "./ReferralOfferingGridView";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import ReferralOfferingForm from "./ReferralOfferingForm";

// Define a type for the offering
type Offering = {
  id: string;
  referralTrackingId: string;
  type: string;
  isCurrent: boolean;
  moreInfoLinks: { title: string; url: string }[];
  startDate: string;
  endDate: string;
  titleStringBolded: string;
  titleString: string;
  bodyText: string;
  textMessageString: string;
  titleImageUrl: string;
};

const ReferralOfferingView = () => {
  const [offerings, setOfferings] = useState<Offering[]>([]);
  const [selected, setSelected] = useState<Offering | null>(null);
  const [offeringToEdit, setOfferingToEdit] = useState<Offering | null>(null);
  const { token } = useToken();

  async function getOfferings() {
    const url = new URL(`${Base_Url}/referrals/offerings`);
    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };
    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data) {
          setOfferings(data);
        } else {
          throw data.errorMessage;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getOfferings();
  }, []);

  const removeOfferings = async () => {
    if (!selected) return;

    const url = `${Base_Url}/referrals/offerings/offering?id=${selected.id}`;

    return fetch(url, {
      method: "DELETE",
      mode: "cors",
      credentials: "same-origin",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setOfferings((prevOfferings) =>
          prevOfferings.filter((offering) => offering.id !== selected.id)
        );
        setSelected(null);
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEditClick = () => {
    if (selected) {
      setOfferingToEdit(selected);
    }
  };

  const handleClearForm = () => {
    setOfferingToEdit(null);
  };

  return (
    <div className="offeringsGridWrapper">
      <ReferralOfferingForm
        selectedOffering={offeringToEdit}
        onClear={handleClearForm}
      />
      <div className="button">
        <Button variant="primary" onClick={getOfferings}>
          Refresh
        </Button>
        <Button variant="primary" onClick={removeOfferings} disabled={!selected}>
          Remove
        </Button>
        <Button variant="primary" onClick={handleEditClick} disabled={!selected}>
          Edit
        </Button>
      </div>
      <ReferralOfferingGridView
        offerings={offerings}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

export default ReferralOfferingView;
