import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "../Login/Login";
import PortalHome from "../Home/HomeView";
import useToken from "../Login/useToken";
import "./Portal.css";
import { TabProvider } from "../../context/TabContext";

function Portal() {
  const { token, setToken } = useToken();
  return (
    <Router>
      <Routes>
        {token ? (
          <>
            <Route 
              path="/*" 
              element={
                <TabProvider>
                  <PortalHome />
                </TabProvider>
              } 
            />
            <Route path="/login" element={<Navigate to="/users" replace />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login setToken={setToken} />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default Portal;
