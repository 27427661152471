import React, { useState, useEffect } from "react";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  SelectChangeEvent,
  Grid,
} from "@mui/material";
import { Button } from "react-bootstrap";
import './ReferralOfferingForm.scss';

interface FormErrors {
  id?: string;
  referralTrackingId?: string;
  type?: string;
  isCurrent?: string;
  moreInfoLinks?: string;
  startDate?: string;
  endDate?: string;
  titleStringBolded?: string;
  titleString?: string;
  bodyText?: string;
  textMessageString?: string;
  titleImageUrl?: string;
}

interface MoreInfoLink {
  title: string;
  url: string;
}

interface FormValues {
  id: string;
  referralTrackingId: string;
  type: string;
  isCurrent: boolean;
  moreInfoLinks: MoreInfoLink[];
  startDate: string;
  endDate: string;
  titleStringBolded: string;
  titleString: string;
  bodyText: string;
  textMessageString: string;
  titleImageUrl: string;
}

interface ReferralOfferingFormProps {
  selectedOffering: FormValues | null;
  onClear: () => void;
}

const ReferralOfferingForm: React.FC<ReferralOfferingFormProps> = ({ selectedOffering, onClear }) => {
  const { token } = useToken();
  const initialFormValues: FormValues = {
    id: "",
    referralTrackingId: "",
    type: "",
    isCurrent: false,
    moreInfoLinks: [{ title: "", url: "" }],
    startDate: "",
    endDate: "",
    titleStringBolded: "",
    titleString: "",
    bodyText: "",
    textMessageString: "",
    titleImageUrl: "",
  };

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [errors, setErrors] = useState<FormErrors>({});
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    if (selectedOffering) {
      const formatDate = (dateString: string | undefined | null) => {
        if (!dateString) {
          return "";
        }
        if (dateString.includes('T')) {
          return dateString.split('T')[0];
        }
        return dateString;
      };

      setFormValues({
        ...selectedOffering,
        startDate: formatDate(selectedOffering.startDate),
        endDate: formatDate(selectedOffering.endDate),
        isCurrent: selectedOffering.isCurrent,
        titleImageUrl: selectedOffering.titleImageUrl || "",
      });
    }
  }, [selectedOffering]);

  useEffect(() => {
    setIsFormValid(validate());
  }, [formValues]);

  const validate = () => {
    const newErrors: FormErrors = {};
    if (!formValues.id) newErrors.id = "Required";
    if (!formValues.referralTrackingId) newErrors.referralTrackingId = "Required";
    if (!formValues.type) newErrors.type = "Required";
    if (!formValues.startDate) newErrors.startDate = "Required";
    // if (!formValues.endDate) newErrors.endDate = "Required";
    if (!formValues.titleStringBolded) newErrors.titleStringBolded = "Required";
    if (!formValues.titleString) newErrors.titleString = "Required";
    if (!formValues.bodyText) newErrors.bodyText = "Required";
    if (!formValues.textMessageString) newErrors.textMessageString = "Required";
    // if (!formValues.titleImageUrl) newErrors.titleImageUrl = "Required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleMoreInfoLinkChange = (index: number, field: keyof MoreInfoLink, value: string) => {
    const updatedLinks = [...formValues.moreInfoLinks];
    updatedLinks[index] = { ...updatedLinks[index], [field]: value };
    setFormValues({ ...formValues, moreInfoLinks: updatedLinks });
  };

  const addMoreInfoLink = () => {
    setFormValues({
      ...formValues,
      moreInfoLinks: [...formValues.moreInfoLinks, { title: "", url: "" }],
    });
  };

  const removeMoreInfoLink = (index: number) => {
    const updatedLinks = formValues.moreInfoLinks.filter((_, i) => i !== index);
    setFormValues({ ...formValues, moreInfoLinks: updatedLinks });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const response = await fetch(`${Base_Url}/referrals/offerings/offering`, {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });

      if (!response.ok) {
        throw new Error("Failed to add or update offering");
      }

      const data = await response.json();
      console.log("Offering added/updated:", data);
    } catch (error) {
      console.error("Error adding/updating offering:", error);
    }
  };

  const handleClear = () => {
    setFormValues(initialFormValues);
    onClear();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth margin="normal" error={!!errors.id}>
            <TextField
              id="id"
              name="id"
              label="ID"
              type="text"
              onChange={
                handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
              }
              value={formValues.id}
              helperText={errors.id}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl fullWidth margin="normal" error={!!errors.referralTrackingId}>
            <TextField
              id="referralTrackingId"
              name="referralTrackingId"
              label="Referral Tracking ID"
              type="text"
              onChange={
                handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
              }
              value={formValues.referralTrackingId}
              helperText={errors.referralTrackingId}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl fullWidth margin="normal" error={!!errors.type}>
            <TextField
              id="type"
              name="type"
              label="Type"
              type="text"
              onChange={
                handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
              }
              value={formValues.type}
              helperText={errors.type}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="isCurrent-label">Is Current</InputLabel>
            <Select
              labelId="isCurrent-label"
              id="isCurrent"
              name="isCurrent"
              onChange={(event) => {
                const value = event.target.value === "true";
                setFormValues({ ...formValues, isCurrent: value });
              }}
              value={formValues.isCurrent ? "true" : "false"}
              label="Is Current"
            >
              <MenuItem value="true">True</MenuItem>
              <MenuItem value="false">False</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {formValues.moreInfoLinks.map((link, index) => (
        <Grid container spacing={2} alignItems="center" key={index}>
          <Grid item xs={5}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Link Title"
                value={link.title}
                onChange={(e) => handleMoreInfoLinkChange(index, "title", e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Link URL"
                value={link.url}
                onChange={(e) => handleMoreInfoLinkChange(index, "url", e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <Button variant="danger" onClick={() => removeMoreInfoLink(index)}>
              Remove
            </Button>
          </Grid>
        </Grid>
      ))}

      <Button variant="primary" onClick={addMoreInfoLink} style={{ marginTop: "12px", marginBottom: "12px" }}>
        Add More Info Link
      </Button>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal" error={!!errors.startDate}>
            <TextField
              id="startDate"
              name="startDate"
              label="Start Date"
              type="date"
              onChange={
                handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
              }
              value={formValues.startDate}
              helperText={errors.startDate}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal" error={!!errors.endDate}>
            <TextField
              id="endDate"
              name="endDate"
              label="End Date"
              type="date"
              onChange={
                handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
              }
              value={formValues.endDate}
              helperText={errors.endDate}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal" error={!!errors.titleStringBolded}>
            <TextField
              id="titleStringBolded"
              name="titleStringBolded"
              label="Title String Bolded"
              type="text"
              onChange={
                handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
              }
              value={formValues.titleStringBolded}
              helperText={errors.titleStringBolded}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal" error={!!errors.titleString}>
            <TextField
              id="titleString"
              name="titleString"
              label="Title String"
              type="text"
              onChange={
                handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
              }
              value={formValues.titleString}
              helperText={errors.titleString}
            />
          </FormControl>
        </Grid>
      </Grid>

      <FormControl fullWidth margin="normal" error={!!errors.bodyText}>
        <TextField
          id="bodyText"
          name="bodyText"
          label="Body Text"
          type="text"
          onChange={
            handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
          }
          value={formValues.bodyText}
          helperText={errors.bodyText}
        />
      </FormControl>

      <FormControl fullWidth margin="normal" error={!!errors.textMessageString}>
        <TextField
          id="textMessageString"
          name="textMessageString"
          label="Text Message String"
          type="text"
          onChange={
            handleChange as (event: React.ChangeEvent<HTMLInputElement>) => void
          }
          value={formValues.textMessageString}
          helperText={errors.textMessageString}
        />
      </FormControl>

      <FormControl fullWidth margin="normal" error={!!errors.titleImageUrl}>
        <TextField
          id="titleImageUrl"
          name="titleImageUrl"
          label="Title Image URL"
          type="text"
          onChange={(e) => setFormValues({ ...formValues, titleImageUrl: e.target.value })}
          value={formValues.titleImageUrl}
          helperText={errors.titleImageUrl}
        />
      </FormControl>

      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <Button variant="secondary" onClick={handleClear} style={{ marginRight: "10px" }}>
          Clear
        </Button>
        <Button variant="primary" type="submit" disabled={!isFormValid}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default ReferralOfferingForm;
