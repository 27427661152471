const env = process.env.REACT_APP_ENV;

export const Base_Url = (() => {
  switch (env) {
    case 'production':
      return "https://api.admin.grid.dating";
    case 'development':
      return "https://dev.api.admin.grid.dating";
    case 'local':
      return "http://localhost:8000";
    default:
      return "http://localhost:8000"; // Fallback to local if no environment is set
  }
})();

