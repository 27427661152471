import React from "react";
import { Routes, Route, NavLink, Navigate } from "react-router-dom";
import PortalRestaurantSearchManager from "../GoogleVenueSearch/PortalRestaurantSearchManager";
import VenuesView from "../Venues/VenuesView";
import UserManager from "../UsersManager/UserManager";
import ReportManager from "../ReportManager/ReportManager";
import UserProfile from "../UserProfile/UserProfile";
import { MatchesView } from "../Matches/MatchesView";
import EventsView from "../Events/EventsView";
import "./HomeView.scss";
import { UserInfoViewProvider } from "../../context/UserInfoContext";
import NotificationsView from "../Notifications/NotificationsView";
import { Nav } from 'react-bootstrap';
import ReferralOfferingView from "../ReferralOffering/ReferralOfferingView";
import ReferralsView from "../Referrals/ReferralsView";
const PortalHome = () => {
  return (
    <div className="home-view-wrapper">
      <UserInfoViewProvider>
        <Nav variant="tabs" className="mb-3">
          <Nav.Link as={NavLink} to="/users">Users</Nav.Link>
          <Nav.Link as={NavLink} to="/user">User Profiles</Nav.Link>
          <Nav.Link as={NavLink} to="/matches">Matches</Nav.Link>
          <Nav.Link as={NavLink} to="/resturants">Restaurant Manager</Nav.Link>
          <Nav.Link as={NavLink} to="/resturants-search">Restaurant Search</Nav.Link>
          <Nav.Link as={NavLink} to="/reports">Report Manager</Nav.Link>
          <Nav.Link as={NavLink} to="/notifications">Notifications</Nav.Link>
          <Nav.Link as={NavLink} to="/events">Events</Nav.Link>
          <Nav.Link as={NavLink} to="/referrals-offerings">Referrals Offerings</Nav.Link>
          <Nav.Link as={NavLink} to="/referrals">Referrals</Nav.Link>
        </Nav>
        <Routes>
          <Route path="/resturants-search" element={<PortalRestaurantSearchManager />} />
          <Route path="/resturants" element={<VenuesView />} />
          <Route path="/users" element={<UserManager />} />
          <Route path="/matches" element={<MatchesView />} />
          <Route path="/reports" element={<ReportManager />} />
          <Route path="/user" element={<UserProfile />} />
          <Route path="/notifications" element={<NotificationsView />} />
          <Route path="/events" element={<EventsView />} />
          <Route path="/" element={<Navigate to="/users" replace />} />
          <Route path="/referrals-offerings" element={<ReferralOfferingView />} />
          <Route path="/referrals" element={<ReferralsView />} />
        </Routes>
      </UserInfoViewProvider>
    </div>
  );
};

export default PortalHome;
