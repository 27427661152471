import React from "react";
import { Button } from "react-bootstrap";
import useToken from "../Login/useToken";
import { Base_Url } from "../../constants/constants";

interface RemoveUserDislikesProps {
  userId: string;
  onDislikesRemoved?: () => void;
}

const RemoveUserDislikes: React.FC<RemoveUserDislikesProps> = ({ userId, onDislikesRemoved }) => {
  const { token } = useToken();

  const handleRemoveDislikes = async () => {
    if (!userId) {
      return;
    }
    const url = new URL(`${Base_Url}/users/user/dislikes`);
    url.searchParams.append("userId", userId);
    const options = {
      method: 'DELETE',
      headers: new Headers({
        'Authorization': token,
        'Content-Type': 'application/json'
      })
    };

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        console.log("Dislikes removed successfully.");
        if (onDislikesRemoved) {
          onDislikesRemoved();
        }
      } else {
        throw response;
      }
    } catch (error) {
      console.error("An error occurred while removing dislikes:", error);
    }
  };

  return (
    <Button variant="primary" onClick={handleRemoveDislikes}>
      Remove Dislikes
    </Button>
  );
};

export default RemoveUserDislikes;
